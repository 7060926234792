import axios from './shein-axios/index'
import { synchronizeInstance } from './shein-axios/syncXhrAdapter'
import { forceLogin, apiLog, syncCommonHeader, dataAggregation, tongdunSignature } from './interceptors'

const schttpSync = (() => {
    // 谨防 SSR 改写 node axios
    if (typeof window === 'undefined') return axios
    const instance = synchronizeInstance(axios.create({
      baseURL: gbCommonInfo.langPath,
      headers: {
        common: {
          'x-requested-with': 'XMLHttpRequest'
        }
      },
      params: {
        _ver: '1.1.8',
        _lang: gbCommonInfo.appLanguage
      },
    }))

    // ========== set your interceptors here ==========
    instance.interceptors.request.use(config => { config.headers['x-csrf-token'] = gbCommonInfo.csrf_token; return config })
    instance.interceptors.request.use(syncCommonHeader)
    instance.interceptors.request.use(tongdunSignature)
    instance.interceptors.request.use(dataAggregation)
    // -- request   ↑
    // -- response  ↓
    instance.interceptors.response.use(apiLog.success, apiLog.error)
    instance.interceptors.response.use(forceLogin)
    // ========== END ==========
    instance.interceptors.response.use(
      response => response.config?.schttp?.getResponse ? response : response.data,
      response => { throw response.config?.schttp?.getResponse || !response.request ? response : response.request }
    )

    window.schttpSync = instance
    return instance
})()

/** 
 * ========== best practice ==========
 * import schttpSync from 'public/src/services/schttp/sync'
 * 只使用对象形式请求, 以便未来技术替换
 * https://axios-http.com/zh/docs/req_config
 * 
 * schttpSync({
 *   // method: 'GET', // by default
 *   url: '/somePath/someApi',
 *   params: { foo: 'bar' }
 * })
 * 
 * schttpSync({
 *   method: 'POST',
 *   url: '/somePath/someApi',
 *   data: { foo: 'bar' }
 * })
 */

export default schttpSync
